import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.BE,
  locale: Locales['nl-BE'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    long: 'EEEE d MMMM yyyy',
    midsize: 'dd MMMM yyyy',
    short: 'dd/MM/yyyy',
  },
}

export type PossibleLocales = keyof Pick<typeof Locales, 'nl-BE' | 'fr-BE'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'nl-BE': INTL_DEFAULTS,
  'fr-BE': {
    ...INTL_DEFAULTS,
    locale: Locales['fr-BE'],
    dateFormat: {
      long: 'EEEE d MMMM, yyyy',
      midsize: 'dd MMMM, yyyy',
      short: 'dd/MM/yyyy',
    },
  },
}
